<template>
  <div class="w-full h-full pt-5 px-3">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4">
          <h1 class="text-xl font-extrabold">Employees</h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <div class="flex flex-col gap-2">
          <template>
            <Card class="mt-6 p-5 flex flex-col gap-5">
              <template>
                <div class="flex mb-6 mr-3" style="justify-content: flex-end">
                  <s-button
                    label="New Promotion"
                    class="text-white button-class"
                    :style="{
                      background: appearance.buttonColor || defaultBTNColor,
                    }"
                    @onClick="priviledgedActions(null, 'create')"
                    v-if="mode === 'admin'"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <CardFooter
                  reloadcard
                  @reload="onGetQuery"
                  @sortType="onGetQuery({ sort: $event })"
                  @searchResult="onGetQuery({ search: $event })"
                />
              </template>
            </Card>
          </template>
          <template v-if="hasModuleAccess">
            <sTable
              v-if="employees.length || isFetching"
              :headers="headers"
              :items="employees"
              :loading="isFetching"
              :pagination-list="pagination"
              @page="onGetQuery({ page: $event })"
              @itemsPerPage="onGetQuery({ perPage: $event })"
              page-sync
            >
              <template v-slot:item="{ item }">
                <div class="flex whitespace-nowrap" v-if="item.userId">
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="profile pic"
                    style="
                      height: 35px;
                      width: 35px;
                      border-radius: 5px;
                      object-fit: cover;
                    "
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2 w-8 h-8"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                  <div class="ml-2 text-sm flex flex-col justify-Start">
                    <span
                      class="font-bold cursor-pointer"
                      style="color: #525252"
                    >
                      {{ item.data.fname }} {{ item.data.lname }}
                    </span>
                    <div class="text-romanSilver text-xs uppercase">
                      <span v-if="item.data.orgFunction">
                        {{ item.data.orgFunction.name }}
                      </span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <span v-else-if="item.createdAt" class="">
                  {{
                    $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                  }}
                </span>
                <div v-else-if="item.newDesignation" class="">
                  <span v-if="item.data.newDesignation">
                    {{ item.data.newDesignation.name }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-else-if="item.newPayInfo" class="">
                  <span v-if="item.data.newPayInfo">
                    {{ convertToCurrency(item.data.newPayInfo.rangeMinimum) }} -
                    {{ convertToCurrency(item.data.newPayInfo.rangeMaximum) }}
                  </span>
                  <span v-else>-</span>
                </div>
                <span v-else-if="item.newAnnualSalary" class="">
                  {{ convertToCurrency(item.data.newAnnualSalary) }}
                </span>
                <span v-else-if="item.effectiveDate" class="">
                  {{
                    $DATEFORMAT(
                      new Date(item.data.effectiveDate),
                      "MMMM dd, yyyy"
                    )
                  }}
                </span>
                <div
                  v-else-if="item.initiatedBy && item.data.initiatedBy"
                  class=""
                >
                  {{ item.data.initiatedBy.fname }}
                  {{ item.data.initiatedBy.lname }}
                </div>
                <p
                  v-else-if="item.status"
                  class="text-center capitalize w-24"
                  :class="{
                    approved: item.data.status === 'approved',
                    disapproved: item.data.status === 'disapproved',
                    cancelled: item.data.status === 'cancelled',
                    disapproved: item.data.status === 'terminated',
                    pending: item.data.status === 'pending',
                  }"
                >
                  {{ item.data.status }}
                </p>
                <span v-else-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <icon icon-name="more_icon" size="xs" />
                    </template>
                    <div
                      class="w-44 flex flex-col justify-start items-center gap-3 px-3 py-2"
                    >
                      <template v-if="!item.data.approvalInitiated">
                        <div
                          class="w-full"
                          @click="priviledgedActions(item.data, 'update')"
                        >
                          <div
                            class="w-full flex justify-start items-center gap-2 cursor-pointer"
                          >
                            <Icon
                              icon-name="edit"
                              class-name="text-blueCrayola"
                              size="xs"
                            />
                            <p class="font-normal text-sm text-darkPurple">
                              Edit
                            </p>
                          </div>
                        </div>
                      </template>
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data, 'view_details')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-eye"
                            class-name="text-darkPurple"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            View Details
                          </p>
                        </div>
                      </div>
                      <div
                        class="w-full"
                        @click="priviledgedActions(item.data.id, 'approval')"
                      >
                        <div
                          class="w-full flex justify-start items-center gap-2 cursor-pointer"
                        >
                          <Icon
                            icon-name="icon-upgrade"
                            class-name="text-mediumSeaGreen"
                            size="xs"
                          />
                          <p class="font-normal text-sm text-darkPurple">
                            Approval Status
                          </p>
                        </div>
                      </div>
                      <template v-if="!item.data.approvalInitiated">
                        <div
                          class="w-full"
                          @click="priviledgedActions(item.data.id, 'cancel')"
                          v-if="mode === 'ess'"
                        >
                          <div
                            class="w-full flex justify-start items-center gap-2 cursor-pointer"
                          >
                            <Icon
                              icon-name="icon-unavailable"
                              class-name="text-romanSilver"
                              size="xs"
                            />
                            <p class="font-normal text-sm text-darkPurple">
                              Cancel
                            </p>
                          </div>
                        </div>
                      </template>
                    </div>
                  </Menu>
                </span>
              </template>
            </sTable>
            <div
              v-else
              class="w-full flex flex-col justify-center items-center"
            >
              <icon icon-name="empty_agreement" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                There are no pending promotion. To view and manage employee
                promotions, kindly click below to start a new employee
                promotion.
              </div>
              <div
                @click="priviledgedActions(null, 'create')"
                class="flex justify-start items-center cursor-pointer gap-2 mt-4"
                v-if="mode === 'admin'"
              >
                <Icon icon-name="icon-plus" size="xs" />
                <span class="font-semibold text-base text-flame leading-5">
                  New Promotion
                </span>
              </div>
            </div>
          </template>
          <div v-else>
            <ErrorComponent />
          </div>
        </div>
      </template>
      <template>
        <Show
          ref="show_promotion"
          :mode="mode"
          @trail="$refs.approval_status.toggle($event)"
        />
        <Create
          @promoted="onGetQuery"
          ref="create_promotion"
          @close="$refs.create_promotion.toggle()"
        />
        <Update
          @promoted="onGetQuery"
          ref="update_promotion"
          @close="$refs.update_promotion.toggle()"
        />
        <ApprovalProgress ref="approval_status" />
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as _ from "lodash";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import SButton from "@scelloo/cloudenly-ui/src/components/button";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  components: {
    Breadcrumb,
    STable,
    Card,
    SButton,
    CardFooter,
    Menu,
    ErrorComponent,
    ApprovalProgress: () => import("@/components/ApprovalStatus"),
    Update: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Promotion/update"),
    Create: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Promotion/create"),
    Show: () =>
      import("@/modules/Admin/EmployeeModule/EmployeeActions/Promotion/show"),
  },
  data() {
    return {
      isLoading: true,
      isFetching: true,
      queryParams: {},
      approvalModal: false,
      hasModuleAccess: false,
      employees: [],
      pagination: {},
      headers: [
        { title: "Employee Name", value: "userId" },
        { title: "date created", value: "createdAt" },
        { title: "New Designation", value: "newDesignation" },
        { title: "new pay group/range", value: "newPayInfo" },
        { title: "new gross salary", value: "newAnnualSalary" },
        { title: "effective date", value: "effectiveDate" },
        { title: "initiated by", value: "initiatedBy" },
        { title: "status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      breadcrumbs: [
        { disabled: false, text: "Employee", href: "", id: "Employee" },
        {
          disabled: false,
          text: "Promotion",
          href: "promotion",
          id: "Promotion",
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    async onGetQuery(queryParams) {
      try {
        if (this.mode === "admin") {
          await this.$handlePrivilege("promotionHR", "viewRegisterPromotion");
        }
        if (queryParams !== undefined) {
          this.queryParams = { ...this.queryParams, ...queryParams };
          this.getOrgPromotedEmployees(this.queryParams);
        } else {
          this.queryParams = {};
          this.getOrgPromotedEmployees();
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    async priviledgedActions(payload, setAction) {
      try {
        if (setAction === "create") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("promotionHR", "createNewPromotion");
          }
          this.$refs.create_promotion.toggle(payload);
        } else if (setAction === "update") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("promotionHR", "editPromotion");
          }
          this.$refs.update_promotion.toggle(payload);
        } else if (setAction === "view_details") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("promotionHR", "viewPromotionDetails");
          }
          this.$refs.show_promotion.toggle(payload);
        } else if (setAction === "approval") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("promotionHR", "viewApprovalStatus");
          }
          this.$refs.approval_status.toggle(payload);
        } else if (setAction === "cancel") {
          if (this.mode === "admin") {
            await this.$handlePrivilege("promotionHR", "cancelPromotion");
          }
          this.cancelPromotion(payload);
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    async getOrgPromotedEmployees(params = '') {
      this.isLoading= true;
      const formattedParams = Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
      if (this.mode === "admin") {
        await this.$_getPromotedEmployee(formattedParams).then(({ data }) => {
          this.employees = data.data.employees
            ?.filter((item) => _.size(_.keys(item)))
            .map((item) => ({
              ...item,
              ...item.newDetails.employee,
              payInfo: item.payInfo,
              grossPay: this.setGrossPay(item),
              newDesignation: item.newDetails.newDesignation,
              newPayInfo: item.newDetails.employee.payInfo,
              initiatedBy: item?.newDetails?.initiatedBy,
            }));
          this.pagination = data.data.meta;
          this.isLoading = false;
          this.isFetching = false;
        });
      } else {
        await this.$_getManagerEmployeesPromotion(formattedParams).then(({ data }) => {
          this.employees = data.data.employees
            ?.filter((item) => _.size(_.keys(item)))
            .map((item) => ({
              ...item,
              ...item.newDetails.employee,
              payInfo: item.payInfo,
              grossPay: this.setGrossPay(item),
              newDesignation: item.newDetails.newDesignation,
              newPayInfo: item.newDetails.employee.payInfo,
              initiatedBy: item?.newDetails?.initiatedBy,
            }));
          this.pagination = data.data.meta;
          this.isLoading = false;
          this.isFetching = false;
        });
      }
    },

    cancelPromotion(id) {
      const payload = {
        promotionId: id,
      };
      this.$_cancelEmployeesPromotion(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getOrgPromotedEmployees("");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, {
            duration: 5000,
          });
        });
    },

    setGrossPay(item) {
      if (item.payInfo) {
        return item.payInfo.grossPay ? item.payInfo.grossPay : null;
      }
      return null;
    },
  },
  async created() {
    try {
      if (this.mode === "admin") {
        await this.$handlePrivilege("promotionHR", "viewRegisterPromotion");
      }
      this.hasModuleAccess = true;
      this.getOrgPromotedEmployees("");
    } catch (error) {
      this.hasModuleAccess = false;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.cancelled {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.button-class {
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
</style>
